'use strict';

window.jQuery = require('jquery');
window.$ = window.jQuery;

require('./util/webpackSetup');

var processInclude = require('base/util');

$(document).ready(function () {
    processInclude(require('./common/handleUncachedData'));
    processInclude(require('./common/copyElements'));
    processInclude(require('./product/arTryOn.js'));
    processInclude(require('./components/spinner'));
    processInclude(require('./components/search'));
    processInclude(require('./rotatingPromoBar'));
    processInclude(require('./components/menu'));
    import(/* webpackPreload: true */ /* webpackChunkName: "minicart-chunk" */ './components/miniCart').then(function (module) { processInclude(module.default); });
    import(/* webpackPreload: true */ /* webpackChunkName: "conponents-chunk" */ './video').then(function (module) { processInclude(module.default); });
    processInclude(require('base/components/cookie'));
    processInclude(require('base/components/consentTracking'));
    processInclude(require('./components/collapsibleItem'));
    processInclude(require('./components/clientSideValidation'));
    processInclude(require('base/components/countrySelector'));
    processInclude(require('./components/customSelect'));
    processInclude(require('./globalModal/globalModal'));
    processInclude(require('./product/afterpayContent'));
    processInclude(require('./components/formValidation'));
    import(/* webpackPreload: true */ /* webpackChunkName: "conponents-chunk" */ './contentComponents/carouselTabInitializer').then(function (module) { processInclude(module.default); });
    import(/* webpackPreload: true */ /* webpackChunkName: "conponents-chunk" */ './contentComponents/contentComponentBase').then(function (module) { processInclude(module.default); });
    import(/* webpackPreload: true */ /* webpackChunkName: "conponents-chunk" */ './contentComponents/einsteinCarousel').then(function (module) { processInclude(module.default); });
    processInclude(require('./discount/discountPopUp'));
    processInclude(require('./components/componentMask'));
    processInclude(require('./changeCountry/countryCheck'));
    processInclude(require('./common/ada'));
    processInclude(require('./common/headerScrollHandler'));
    import(/* webpackPreload: true */ /* webpackChunkName: "footer-chunk" */ 'base/components/footer').then(function (module) { processInclude(module.default); });
    processInclude(require('./components/toolTip'));
    processInclude(require('./help/zendesk'));
    processInclude(require('./components/specialEmailSubscribe'));
    processInclude(require('./pageDesignerEmailSubscribe.js'));
    processInclude(require('./timerCountdownEvents'));
    processInclude(require('./common/prefetchAssets'));
    processInclude(require('./storeLocator/storeFinder'));
    processInclude(require('./help/embeddedChat'));
    processInclude(require('./common/globalEvents'));
    processInclude(require('./common/prerender'));
    // Web components.
    require('./web-components/accordionComponent').init();
    require('./web-components/accordionGroupComponent').init();
    require('./web-components/contentLoaderElement').init();
    require('./web-components/countdownElement').init();
    require('./web-components/verticalProdCarouselElement').init();
    processInclude(require('./contentComponents/comparisonTile'));
    processInclude(require('./contentComponents/styliticsComponents'));
    processInclude(require('./contentComponents/tabsLayout'));
});
// eslint-disable-next-line no-unused-expressions
/* webpackChunkName: "thirdPartyBootstrap" */
require('./thirdParty/bootstrap');
// eslint-disable-next-line no-unused-expressions
require('jquery.cookie');

require('@accessible360/accessible-slick/slick/slick.min.js');
